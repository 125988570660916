import React, { CSSProperties, memo } from 'react';
import cn from 'classnames';
import s from './FeaturesList.module.scss';

interface Item {
  label: string;
  icon: () => React.ReactNode;
}

interface Props {
  items: Item[];
  separatorStyle?: CSSProperties;
  containerStyle?: CSSProperties;
  iconStyle?: CSSProperties;
}

export default memo(function FeaturesList({
  items,
  separatorStyle,
  containerStyle,
  iconStyle,
}: Props) {
  return (
    <div className={cn(s.box, s.features)}>
      {items.map((item, key) => (
        <div key={key}>
          <div className={s.separator} style={separatorStyle} />
          <div className={s.container} style={containerStyle}>
            <div style={{ ...iconStyle }}>{item.icon()}</div>
            <div className={s.label}>{item.label}</div>
          </div>
        </div>
      ))}
      <div>
        <div className={s.separator} />
      </div>
    </div>
  );
});
