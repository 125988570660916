import { COUPON_REDUCTION_TYPE } from 'store/Guests/types';

interface ChatEventChannels {
  event: { fr: string; nl: string; en: string };
  stage: { fr: string; nl: string; en: string };
}

export const getChannelIdsByEvents = (
  eventId: number,
  organizationId?: number,
): ChatEventChannels | undefined => {
  const channels: {
    [key: number]: ChatEventChannels;
  } = {
    119: {
      event: {
        fr: 'group/congress-fff-2020-fr',
        nl: 'group/congress-fff-2020-nl',
        en: 'group/congress-fff-2020-en',
      },
      stage: {
        fr: 'group/congress-fff-2020-fr',
        nl: 'group/congress-fff-2020-nl',
        en: 'group/congress-fff-2020-en',
      },
    },
    311: {
      event: {
        fr: 'channel/congress-fff-2021-fr',
        nl: 'channel/congress-fff-2021-nl',
        en: 'channel/congress-fff-2021-en',
      },
      stage: {
        fr: 'channel/congress-fff-2021-fr',
        nl: 'channel/congress-fff-2021-nl',
        en: 'channel/congress-fff-2021-en',
      },
    },
    314: {
      event: {
        fr: 'channel/event-314-fr',
        nl: 'channel/event-314-nl',
        en: 'channel/event-314-en',
      },
      stage: {
        fr: 'channel/event-314-fr',
        nl: 'channel/event-314-nl',
        en: 'channel/event-314-en',
      },
    },
    120: {
      event: {
        fr: 'group/event-120-fr',
        nl: 'group/event-120-fr',
        en: 'group/event-120-fr',
      },
      stage: {
        fr: 'group/event-120-fr',
        nl: 'group/event-120-fr',
        en: 'group/event-120-fr',
      },
    },
    137: {
      event: {
        fr: 'channel/fff-2021-fr',
        nl: 'channel/fff-2021-nl',
        en: 'channel/fff-2021-en',
      },
      stage: {
        fr: 'channel/fff-2021-fr',
        nl: 'channel/fff-2021-nl',
        en: 'channel/fff-2021-en',
      },
    },
  };

  if (channels[eventId]) {
    return channels[eventId];
  }

  const _eventId = organizationId === 9 && eventId >= 137 ? 137 : eventId;

  return channels[_eventId];
};

interface EventSlotTypes {
  demos: number[];
  breakOuts: number[];
}

export const getActivitiesTypesByEvent = (eventId: string): EventSlotTypes => {
  const types: {
    [key: number]: EventSlotTypes;
  } = {
    119: {
      demos: [209],
      breakOuts: [210],
    },
  };

  return types[parseInt(eventId)] ?? { demos: [], breakOuts: [] };
};

/**
 * Structural partners
 *
 * 891	Syneton Adminpulse
 * 892	Billtobox
 * 894	WinBooks
 * 895	FID-Manager
 * 897	Belgian Institute for Tax Advisors and Accountants
 *
 * Partners
 *
 * 898	Billit
 * 899	Larcier Intersentia
 * 900	Bothive
 * 901	Cloudbizz
 * 902	WinAuditor
 * 903	Skwarel
 * 904	Bizzcontrol
 * 905	Service Public Fédéral Finances
 * 908	Octopus Accountancy Software
 * 909	CaseWare Belgium & Luxembourg
 * 910	Isabel Group
 * 1014	PENNEO A/S
 * 1019	Forum for the Future
 */

const START_DAY = 24;
const NUMBER_DAYS = 4;
export const getPartnersSort = (
  day: number,
  isStructural: boolean,
): number[] => {
  switch ((day % START_DAY) % NUMBER_DAYS) {
    case 0:
      if (isStructural) {
        return [891, 892, 894, 895, 897];
      }
      return [
        898,
        899,
        900,
        901,
        902,
        903,
        904,
        905,
        908,
        909,
        910,
        1014,
        1019,
      ];
    case 1:
      if (isStructural) {
        return [895, 897, 892, 894, 891];
      }
      return [
        903,
        909,
        910,
        1014,
        905,
        900,
        1019,
        908,
        901,
        899,
        904,
        898,
        902,
      ];
    case 2:
      if (isStructural) {
        return [897, 894, 895, 892, 891];
      }
      return [
        900,
        901,
        909,
        905,
        910,
        908,
        898,
        904,
        1014,
        1019,
        902,
        903,
        899,
      ];
    case 3:
      if (isStructural) {
        return [892, 891, 895, 894, 897];
      }
      return [
        904,
        899,
        910,
        902,
        898,
        903,
        901,
        905,
        1019,
        908,
        900,
        909,
        1014,
      ];
    default: {
      if (isStructural) {
        return [891, 892, 894, 895, 897];
      }
      return [
        898,
        899,
        900,
        901,
        902,
        903,
        904,
        905,
        908,
        909,
        910,
        1014,
        1019,
      ];
    }
  }
};

export const EVENT_FULL_ALLOWED_COUPONS = [
  COUPON_REDUCTION_TYPE.EVENT,
  COUPON_REDUCTION_TYPE.PLAN,
  COUPON_REDUCTION_TYPE.OPTION,
];

export const FFF_LOGO =
  'https://s3.tamtam.pro/prod/storage/media/IMAGE/2174/AVATAR_54cbd68e1ba9284a1e486bd5d8551259cd51d96e.png';
export const AG_ITAA = [139];
export const CURRENT_CONGRESS = 311;
export const CURRENT_CONGRESS_ID = 2172;
export const JDI_ID = 740;
export const CONGRESS_IDS = [311, 480, 738, 1874, 2172];
export const SUPER_CYCLE_AUTUMN_FR_ID = 58;
export const SUPER_CYCLE_AUTUMN_NL_ID = 75;
export const SUPER_CYCLE_FR_ID = 43;
export const SUPER_CYCLE_NL_ID = 44;
export const LAST_SPRING_CYCLE_ID = 11;
export const SUPER_CYCLE_CURRENT_SEASON = 4;
export const SUPER_CYCLE_DEFAULT_PRICE = 449;
export const EVENTS_WITH_PACKS = [500];

export const OPTION_REPLAY_DAY1_ID = 345;
export const OPTION_REPLAY_DAY2_ID = 346;
export const OPTION_REPLAY_ALL_ID = 347;
export const OECCBB_ORGANIZATION_ID = 1256;
export const SUPPORTED_LANGUAGES = ['fr', 'nl', 'en'];

export const SEASONS_SPRING_IDS = [SUPER_CYCLE_FR_ID, SUPER_CYCLE_NL_ID];

export const SEASONS_AUTUMN_IDS = [
  SUPER_CYCLE_AUTUMN_FR_ID,
  SUPER_CYCLE_AUTUMN_NL_ID,
];

export const SEASONS_IDS = [
  SUPER_CYCLE_AUTUMN_FR_ID,
  SUPER_CYCLE_AUTUMN_NL_ID,
  SUPER_CYCLE_FR_ID,
  SUPER_CYCLE_NL_ID,
];

export const SPECIAL_CYCLES = [42];

export const CYCLES_SPRING_IDS = [
  7,
  9,
  11,
  16,
  24,
  5,
  8,
  19,
  20,
  6,
  21,
  1,
  18,
  3,
  4,
  17,
  22,
  2,
  10,
  25,
  23,
  29,
  27,
  45,
  42,
];

export const UNLIMITED_PLAN = {
  titleFr:
    'Acces illimité à toutes les formations et cycles organisée par Forum for the future',
  titleNl:
    'Acces illimité à toutes les formations et cycles organisée par Forum for the future',
  titleEn:
    'Acces illimité à toutes les formations et cycles organisée par Forum for the future',
  pricePerMonth: 49,
  oldPricePerYear: 500,
  newPricePerYear: 330,
  startDateTime: '2021-03-24 09:00:00',
  endDateTime: '2021-06-24 09:00:00',
  isReplayable: true,
  logoUrl:
    'https://s3.tamtam.pro/prod/storage/media/IMAGE/2174/AVATAR_54cbd68e1ba9284a1e486bd5d8551259cd51d96e.png',
};

export const CURRENT_CONGRESS_DATES = {
  onSite: ['2022-05-09', '2022-05-10'],
  virtual: ['2021-11-30', '2021-12-01', '2021-12-02', '2021-12-03'],
};

export const CURRENT_CONGRESS_BANNER =
  'https://s3.tamtam.pro/v2/event/assets/images/FFF_2021_Studio.jpg';

export const CYCLES_BANNERS_IMAGES: Record<number, string> = {
  97: '/img/s08-soon-fr.png',
  98: '/img/s08-soon-nl.png',
};

export const ESSENTIAL_DEFAULT_TRAINING_NUMBER = 13;

export const DISABLED_PARTNERS = [1112];

export const REPLAY_UPTIME = 3; // in months

export const PREMIUM_OPTIONS = {
  optionsNl: [
    'Toegang tot de volledige oFFFcourse trainingscatalogus (meer dan 400 trainingen beschikbaar)',
    'Bevat de Live&Replay-optie',
    'Met het LIVE-formaat kunt u direct chatten met de spreker en al uw vragen stellen over het gegeven onderwerp',
    'Met het REPLAY-formaat kunt u uw opleidingen bekijken waar en wanneer u maar wilt. Heeft u een klantafspraak of een onverwachte situatie? Onderbreek de opleiding en hervat op het punt waar u was gebleven',
    'Het volledige opleidingsaanbod is geaccrediteerd door ITAA',
    "Met PREMIUM kunt u zich abonneren op United Associates en uw opleidingscatalogus delen met uw collega's. Meer informatie op https://www.unitedassociates.be",
  ],
  optionsEn: [
    'Access the entire oFFFcourse training catalog (more than 400 training courses available)',
    'Includes Live&Replay option',
    'With the LIVE formula, chat live with the speaker and ask him all your questions on the given theme.',
    'With the REPLAY formula, watch your training wherever and whenever you want. Are you receiving a client or do you have an unexpected event? Stop the training and continue where you left off.',
    'The entire training catalog is ITAA approved.',
    'With PREMIUM, subscribe to United Associates and share your training catalog with your colleagues. More information on www.unitedassociates.be',
  ],
  optionsFr: [
    'Accédez à l’ensemble du catalogue de formation oFFFcourse (+ de 400 formations disponibles)',
    'Comprend l’option Live&Replay',
    'Avec la formule LIVE, chattez en direct en avec l’orateur et posez lui toutes vos questions sur la thématique donnée.',
    'Avec la formule REPLAY, regardez vos formations où et quand vous le souhaitez. Vous recevez un client ou vous avez un imprévu ? Stoppez la formation et reprenez là où vous vous étiez arrêté.',
    'L’ensemble du catalogue de formation est agréé ITAA.',
    'Avec PREMIUM, souscrivez à United Associates et partagez votre catalogue de formation avec vos collaborateurs. Plus d’infos sur www.unitedassociates.be',
  ],
};

export const EVENT_OPTIONS = {
  optionsNl: [
    'Toegang tot 1 opleiding.',
    'LIVE-formaat: Het LIVE-formaat stelt u in staat om rechtstreeks in contact te staan met de spreker. Stel uw vragen tijdens de opleiding met de VRAGEN-ANTWOORDEN-tool of communiceer op een alternatief manier met het publiek via de PEILING-TOOLS en CHAT.',
    'REPLAY-formaat: Bekijk uw opleiding opnieuw waar en wanneer u maar wilt. Het REPLAY-aanbod van de opleiding is beperkt tot het seizoen (Lente-Zomer einde: 31 augustus I Herfst-Winter einde: 31 januari).',
    'ITAA- en OVB-geaccrediteerde LIVE- en REPLAY-opleiding.',
    'Uw inhoud, certificaten en facturen met betrekking tot de opleiding zijn beschikbaar in MIJN RUIMTE oFFFcourse.',
    'ITAA-certificaten worden automatisch geëxporteerd naar het ITAA-portaal.',
  ],
  optionsEn: [
    'Access to 1 training.',
    'LIVE format: The LIVE format allows you to be in a unique relationship with the speaker. Ask him your questions during the training with the QUESTIONS-ANSWERS tool, or interact with the audience with the SURVEY and CHAT tools.',
    'REPLAY format: (Re)view your training wherever and whenever you want. The REPLAY training offer is limited to the season (Spring-Summer: August 31 I Fall-Winter: January 31).',
    'ITAA and OBFG approved LIVE and REPLAY training.',
    'Your content, certificates, invoices relating to the training are available on MY SPACE oFFFcourse.',
    'ITAA certificates are automatically exported to the ITAA portal',
  ],
  optionsFr: [
    'Accès à 1 formation.',
    'Format LIVE : La formule LIVE vous permet d’être en relation unique avec l’orateur. Posez-lui vos questions durant la formation avec l’outil QUESTIONS-REPONSES, ou interagissez avec l’auditoire avec les outils SONDAGES et CHAT.',
    'Format REPLAY : (Re)visionnez votre formation où et quand vous le souhaitez. L’offre REPLAY de la formation est limitée à la saison (Printemps-Été : 31 aout I Automne-Hiver : 31 janvier).',
    'Formation LIVE et REPLAY agréée ITAA et OBFG.',
    'Vos contenus, attestations, factures relatifs à la formation sont disponibles sur MON ESPACE oFFFcourse.',
    "Les attestations ITAA sont automatiquement exportées sur le portail de l'ITAA",
  ],
};

export const CYCLE_OPTIONS = {
  optionsNl: [
    'Toegang tot 2 tot 6 opleidingen rond een specifiek thema.',
    'LIVE-formaat: Het LIVE-formaat brengt u rechtstreeks in contact met de spreker.',
    'Stel uw vragen tijdens de opleiding met de VRAGEN-ANTWOORDEN-tool of communiceer op een alternatief manier met het publiek via de PEILING-TOOLS en CHAT.',
    'REPLAY-formaat: Bekijk uw opleiding opnieuw waar en wanneer u maar wilt. Het REPLAY-aanbod van de CYCLUS is beperkt tot 15 dagen na de datum van de laatste LIVE-uitzending.',
    'ITAA- en OBFG-geaccrediteerde LIVE- en REPLAY-opleiding.',
    'Uw inhoud, certificaten en facturen met betrekking tot de opleiding zijn beschikbaar in MIJN RUIMTE oFFFcourse.',
    'ITAA-certificaten worden automatisch geëxporteerd naar het ITAA-portaal.',
  ],
  optionsEn: [
    'Access into 2 to 6 training courses around a specific theme.',
    'LIVE format: The LIVE format allows you to be in a unique relationship with the speaker. Ask him your questions during the training with the QUESTIONS-ANSWERS tool, or interact with the audience with the SURVEY and CHAT tools.',
    'REPLAY format: (Re)view your training wherever and whenever you want. The REPLAY u CYCLE offer is limited to 15 days after the date of the last LIVE.',
    'ITAA and OBFG approved LIVE and REPLAY training.',
    'Your content, certificates, invoices relating to the training are available on MY SPACE oFFFcourse.',
    'ITAA certificates are automatically exported to the ITAA portal.',
  ],
  optionsFr: [
    "Accès entre 2 à 6 formations autour d'une thématique spécifique.",
    'Format LIVE : La formule LIVE vous permet d’être en relation unique avec l’orateur. Posez-lui vos questions durant la formation avec l’outil QUESTIONS-REPONSES, ou interagissez avec l’auditoire avec les outils SONDAGES et CHAT.',
    'Format REPLAY : (Re)visionnez votre formation où et quand vous le souhaitez. L’offre REPLAY u CYCLE est limitée à 15 jours après la date du derneir LIVE.',
    'Formation LIVE et REPLAY agréée ITAA et OBFG.',
    'Vos contenus, attestations, factures relatifs à la formation sont disponibles sur MON ESPACE oFFFcourse.',
    "Les attestations ITAA sont automatiquement exportées sur le portail de l'ITAA.",
  ],
};

export const SEASON_OPTIONS = {
  optionsNl: [
    'Toegang tot 25 tot 35 opleidingen rond diverse onderwerpen',
    'LIVE-formaat: Het LIVE-formaat stelt u in staat om rechtstreeks in contact te staan met de spreker. Stel uw vragen tijdens de opleiding met de VRAGEN-ANTWOORDEN-tool of communiceer op een alternatief manier met het publiek via de PEILING-TOOLS en CHAT.',
    'REPLAY-formaat: Bekijk uw opleiding opnieuw waar en wanneer u maar wilt. Het REPLAY-aanbod van het SEIZOEN is beperkt (Lente-Zomer: 31 augustus I Herfst-Winter: 31 januari).',
    'ITAA- en OBFG-geaccrediteerde LIVE- en REPLAY-opleiding.',
    'Uw inhoud, certificaten en facturen met betrekking tot de opleiding zijn beschikbaar in MIJN RUIMTE oFFFcourse.',
    'ITAA-certificaten worden automatisch geëxporteerd naar het ITAA-portaal.',
  ],
  optionsEn: [
    'Access into 25 to 35 training courses around different themes.',
    'LIVE format: The LIVE format allows you to be in a unique relationship with the speaker. Ask him your questions during the training with the QUESTIONS-ANSWERS tool, or interact with the audience with the SURVEY and CHAT tools.',
    'REPLAY format: (Re)view your training wherever and whenever you want. The REPLAY SEASON offer is limited (Spring-Summer: August 31 I Fall-Winter: January 31)..',
    'ITAA and OBFG approved LIVE and REPLAY training.',
    'Your content, certificates, invoices relating to the training are available on MY SPACE oFFFcourse.',
    'ITAA certificates are automatically exported to the ITAA portal.',
  ],
  optionsFr: [
    'Accès entre 25 à 35 formations autour de différentes thémtiques.',
    'Format LIVE : La formule LIVE vous permet d’être en relation unique avec l’orateur. Posez-lui vos questions durant la formation avec l’outil QUESTIONS-REPONSES, ou interagissez avec l’auditoire avec les outils SONDAGES et CHAT.',
    'Format REPLAY : (Re)visionnez votre formation où et quand vous le souhaitez. L’offre REPLAY de la SAISON est limitée (Printemps-Été : 31 aout I Automne-Hiver : 31 janvier)..',
    'Formation LIVE et REPLAY agréée ITAA et OBFG.',
    'Vos contenus, attestations, factures relatifs à la formation sont disponibles sur MON ESPACE oFFFcourse .',
    "Les attestations ITAA sont automatiquement exportées sur le portail de l'ITAA.",
  ],
};

export const ESSENTIAL_OPTIONS = {
  optionsNl: [
    'Toegang tot 10 tot 15 opleidingen die de laatste fiscale actualiteiten behandelen.',
    'LIVE-formaat: Het LIVE-formaat stelt u in staat om rechtstreeks in contact te staan met de spreker. Stel uw vragen tijdens de opleiding met de VRAGEN-ANTWOORDEN-tool of communiceer op een alternatief manier met het publiek via de PEILING-TOOLS en CHAT.',
    'REPLAY-formaat: Bekijk uw opleiding opnieuw waar en wanneer u maar wilt. Het REPLAY-aanbod van ESSENTIALS is beperkt tot 15 dagen na de datum van de laatste LIVE-uitzending.',
    'ITAA- en OBFG-geaccrediteerde LIVE- en REPLAY-opleiding.',
    'Uw inhoud, certificaten en facturen met betrekking tot de opleiding zijn beschikbaar in MIJN RUIMTE oFFFcourse.',
    'ITAA-certificaten worden automatisch geëxporteerd naar het ITAA-portaal.',
  ],
  optionsEn: [
    'Access into 10 to 15 training courses covering the latest tax news.',
    'LIVE format: The LIVE format allows you to be in a unique relationship with the speaker. Ask him your questions during the training with the QUESTIONS-ANSWERS tool, or interact with the audience with the SURVEY and CHAT tools.',
    'REPLAY format: (Re)view your training wherever and whenever you want. The ESSENTIALS REPLAY offer is limited to 15 days after the date of the last LIVE.',
    'ITAA and OBFG approved LIVE and REPLAY training.',
    'Your content, certificates, invoices relating to the training are available on MY SPACE oFFFcourse.',
    'ITAA certificates are automatically exported to the ITAA portal.',
  ],
  optionsFr: [
    'Accès entre 10 à 15 formations abordant les dernières actualités fiscales.',
    'Format LIVE : La formule LIVE vous permet d’être en relation unique avec l’orateur. Posez-lui vos questions durant la formation avec l’outil QUESTIONS-REPONSES, ou interagissez avec l’auditoire avec les outils SONDAGES et CHAT.',
    'Format REPLAY : (Re)visionnez votre formation où et quand vous le souhaitez. L’offre REPLAY de ESSENTIALS est limitée à 15 jours après la date du dernier LIVE .',
    'Formation LIVE et REPLAY agréée ITAA et OBFG.',
    'Vos contenus, attestations, factures relatifs à la formation sont disponibles sur MON ESPACE oFFFcourse .',
    "Les attestations ITAA sont automatiquement exportées sur le portail de l'ITAA.",
  ],
};

export const PREMIUM_OFFER_ADVANTAGES = {
  advantagesFr: [
    'Accès à toutes les formations',
    'Accès à tous les cycles',
    'Accès à toutes les saisons',
    'En Live et en Replay',
  ],
  advantagesEn: [
    'Access to all trainings',
    'Access to all cycles',
    'Access to all seasons',
    'Live and Replay',
  ],
  advantagesNl: [
    'Toegang tot alle opleidingen',
    'Toegang tot alle cycli',
    'Toegang tot alle seizoenen',
    'Live en Replay',
  ],
};

export const LIVE_COMING_EVENT_POPUP = {
  popupTimeout: 30, // in seconds
  popupLaunchTime: 59, //  time before and after launching the popup in minutes
  eventPeriodicFetchtime: 3000, // in milliseconds
  eventPeriodicFetchtimeout: 300000, // in milliseconds
};
