import React from 'react';
import useResponsive from 'hooks/useResponsive';
import { PremiumPrice } from 'store/Cycle/types';
import t from 'i18n';
import cn from 'classnames';
import style from './PremiumPlans.module.scss';

interface Props {
  premiumPrice: PremiumPrice;
  className?: string;
}

function PremiumPlans({ premiumPrice, className }: Props) {
  const { isMobile } = useResponsive();
  const monthlyPrice = premiumPrice?.monthlyPrice ?? 0;
  const yearlyPrice = premiumPrice?.yearlyPrice ?? 0;
  const periods = [
    {
      id: 'monthly',
      title: t('Monthly'),
      price: monthlyPrice,
      period: t('month'),
    },
    {
      id: 'yearly',
      title: t('Annuel'),
      price: yearlyPrice,
      relativePrice: monthlyPrice * 12,
      period: t('year'),
    },
  ];

  return (
    <div className={cn(style.periods_container, className)}>
      {periods.map((period) => (
        <div className={cn(style.box)} key={period.id}>
          <div className={style.titleContainer}>
            <div className={cn(style.premium_period, '')}>{period.title}</div>
            {period.id === 'yearly' && isMobile && (
              <span className={style.price_off}>{period.relativePrice} €</span>
            )}
          </div>
          <div className={style.price}>
            <div>
              {period.price} <span className={style.currency}>€</span>
            </div>
            {period.id === 'yearly' && !isMobile && (
              <span className={style.price_off}>{period.relativePrice} €</span>
            )}
            <span className={style.period_text}>{`/ ${period.period}`}</span>
          </div>
          {period.id === 'yearly' && (
            <div
              className={style.oeccbbAdvantage}
              style={{
                flexDirection: isMobile ? 'column' : 'row',
              }}
            >
              <span>{t('oeccbbAdvantage1')}</span>
              <span>{t('oeccbbAdvantage2')}</span>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default PremiumPlans;
