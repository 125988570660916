import { Event, EVENT_STATUS, EVENT_TYPES } from 'store/Events/types';
import __, { capitalize, groupBy, isEmpty, isNil } from 'lodash';
import { Language } from 'store/types';
import { AdMediaType, PartnerAd } from 'store/PartnerAds/types';
import { bindLinkData, parseBoolean } from './app';
import {
  Coupon,
  COUPON_REDUCTION_TYPE,
  Guest,
  RegistredChoice,
} from 'store/Guests/types';
import { EventAccess } from 'store/Event/types';
import { parseJson } from './common';
import { Partner } from 'store/Partners/types';
import {
  FFF_ID,
  getPartnersSort,
  LANGUAGES,
  OPTION_REPLAY_ALL_ID,
  OPTION_REPLAY_DAY1_ID,
  OPTION_REPLAY_DAY2_ID,
  TTP_BLOG_URL,
  TTP_API_URL,
  REPLAY_UPTIME,
} from 'config';
import moment from 'moment';
import { URLS } from 'router';
import { CHAT_TAB, REGISTRATION_TAB } from 'Webinar/Tools/services';
import t from 'i18n';
import { CalendarEventType } from 'Common/AddToCalendarBtn/services';
import { EventPlan } from 'interfaces/EventPlan';
import { getCouponReduction, getGuestPrice } from './guest';
import { EventOption } from 'interfaces/EventOption';
import { Cycle, EventCycle } from 'store/Cycle/types';
import { EventPack } from 'interfaces/EventPack';
import { Speaker } from 'store/Speakers/types';
import { Question } from 'interfaces/Question';

const belgiumCurrentdate = new Date().toLocaleString('EN', {
  timeZone: 'Europe/Brussels',
});

export const separateAndSortEvents = (events: Event[]): Event[] => {
  const { past, upcoming } = separateEvents(events);

  return [...sortEvents(upcoming), ...sortEvents(past)];
};

export const sortEvents = (events: Event[]): Event[] =>
  events.sort((a, b) => {
    const dateTimeA = new Date(a.startDateTime).getTime();
    const dateTimeB = new Date(b.startDateTime).getTime();

    return dateTimeB - dateTimeA; // desc sort
  });

/**
 * @param events
 */
export const separateEvents = (
  events: Event[],
): { past: Event[]; upcoming: Event[] } => {
  const past: Event[] = [];
  const upcoming: Event[] = [];
  const now = new Date().getTime();

  events.forEach((event) => {
    const eventDateTime = new Date(event.eventDate).getTime();
    if (eventDateTime < now) {
      past.push(event);
    } else {
      upcoming.push(event);
    }
  });

  return { past, upcoming };
};

/**
 * @param events
 */
export const separateCycleEvents = (
  events: Event[],
): { past: Event[]; upcoming: Event[] } => {
  const past: Event[] = [];
  const upcoming: Event[] = [];
  const now = new Date().getTime();

  events.forEach((event) => {
    const eventDateTime = new Date(event.startDateTime).getTime();
    if (eventDateTime < now) {
      past.push(event as Event);
    } else {
      upcoming.push(event as Event);
    }
  });

  return { past, upcoming };
};

export const getKeyByLanguage = (key: string, language: Language) =>
  `${key}${language.charAt(0).toUpperCase()}${language.slice(1).toLowerCase()}`;

export const getByLanguage = (
  resource: any,
  property: string,
  language: Language,
  strict = false,
): any => {
  if (!resource || typeof resource !== 'object') {
    return '';
  }
  const value = resource[getKeyByLanguage(property, language)];

  if (!__.isEmpty(value)) {
    return value;
  }

  if (strict) {
    return undefined;
  }

  for (const lng of ['Fr', 'Nl', 'En']) {
    const value = resource[`${property}${lng}`];

    if (!__.isEmpty(value)) {
      return value;
    }
  }

  return undefined;
};

export const getNotNullLanguages = (
  resource: any,
  property: string,
): Language[] => {
  if (!resource || typeof resource !== 'object') {
    return [];
  }
  const values: Language[] = [];

  const valueFr = resource[`${property}Fr`];
  const valueNl = resource[`${property}Nl`];
  const valueEn = resource[`${property}En`];

  if (!__.isEmpty(valueFr)) {
    values.push('fr');
  }

  if (!__.isEmpty(valueNl)) {
    values.push('nl');
  }

  if (!__.isEmpty(valueEn)) {
    values.push('en');
  }

  return values;
};

export const parseStringLanguages = (
  languages: string | undefined | null,
): Language[] => {
  if (!languages || isEmpty(languages)) {
    return [];
  }

  return LANGUAGES.filter(
    (lng) => String(languages).indexOf(`"${capitalize(lng)}":"1"`) !== -1,
  );
};

export const getPartnerAdMedia = (
  partnerAd: PartnerAd,
  language: Language,
): string => {
  const media: AdMediaType | undefined = getByLanguage(
    partnerAd.medias,
    'media',
    language,
  );
  return media?.fullMediaUrl ?? '';
};

export const bindExhibitorLink = (
  link: string,
  eventId: string,
  exhibitorId: string,
  params?: string,
) => {
  const linkData = [
    {
      key: ':eventId',
      value: eventId,
    },
    {
      key: ':exhibitorId',
      value: exhibitorId,
    },
  ];

  return bindLinkData(link, linkData, params);
};

export const bindWebinarLink = (
  link: string,
  eventId: string | number,
  params?: string,
) => {
  const linkData = [
    {
      key: ':eventId',
      value: eventId,
    },
  ];

  return bindLinkData(link, linkData, params);
};

export const bindChannelLink = (link: string, channelId: string | number) => {
  const linkData = [
    {
      key: ':channelId',
      value: channelId,
    },
  ];

  return bindLinkData(link, linkData);
};

export const isGuestRegistered = (
  guest?: Guest,
  cycleId?: number,
): { isRegistered: boolean; isRegistredToCycle: boolean; cycleId: number } => {
  const data = {
    isRegistered: false,
    isRegistredToCycle: false,
    cycleId: 0,
  };

  if (!guest) {
    return data;
  }

  const registeredChoice: RegistredChoice | undefined = parseJson(
    guest?.registeredChoice,
  );

  if (isEmpty(registeredChoice) || typeof registeredChoice !== 'object') {
    return data;
  }

  return {
    isRegistered: true,
    cycleId: +registeredChoice.cycle ?? 0,
    isRegistredToCycle: !isNil(cycleId)
      ? +registeredChoice.cycle === cycleId
      : false,
  };
};

export const hasPartnerManagerAccess = (
  eventAccess: EventAccess | null,
  eventId: string | number,
) => {
  const partnersAdm = eventAccess?.partnersAdm ?? [];
  return partnersAdm.some((data) => +data.event === +eventId);
};

export const hasEventAdminAccess = (
  eventAccess: EventAccess | null,
  eventId: string | number,
) => {
  const eventsAdm = eventAccess?.eventsAdm ?? [];
  return eventsAdm.some((id) => +id === +eventId);
};

export const hasEventAccess = (
  eventAccess: EventAccess | null,
  eventId: string | number,
) => {
  const eventsAdm = eventAccess?.eventsAdm ?? [];
  const eventsIds = eventAccess?.events ?? [];
  return [...eventsAdm, ...eventsIds].some((id) => +id === +eventId);
};

export const isPartnerManager = (
  eventAccess: EventAccess | null,
  eventId: string,
  partnerId: string,
) => {
  const partnersAdm = eventAccess?.partnersAdm ?? [];
  return partnersAdm.some(
    (data) =>
      data.event === parseInt(eventId) && data.partner === parseInt(partnerId),
  );
};

export const isFullProgram = (event: Event) => {
  if (!isEventFull(event)) {
    return false;
  }

  const stages = parseJson(event.stages);
  const fullProgram = stages?.fullProgram ?? '';

  switch (fullProgram) {
    case 'false':
      return false;
    case '0':
      return false;
    case 0:
      return false;
    case 'true':
      return true;
    case '1':
      return true;
    case 1:
      return true;
    default:
      return fullProgram === true;
  }
};

export const isRegistrationActive = (
  event: Event,
  isAdmin: boolean = false,
) => {
  const stages = parseJson(event.stages);

  if (+event.type != EVENT_TYPES.EVENT_FULL) {
    return true;
  }

  return (
    stages?.inscription == 'true' || (stages?.inscription == 'admin' && isAdmin)
  );
};

export const isProgramActionsAllowed = (
  event: Event,
  params?: EventHasStageParams,
) => {
  const stages = parseJson(event.stages);

  if (+event.type != EVENT_TYPES.EVENT_FULL) {
    return true;
  }

  return isEventHasStage(stages?.proShowProgramActions, params);
};

export const isEventProgramOpen = (
  event: Event,
  params?: EventHasStageParams,
) => {
  const stages = parseJson(event.stages);

  if (+event.type != EVENT_TYPES.EVENT_FULL) {
    return true;
  }

  return isEventHasStage(stages?.proShowProgram, params);
};

export const isEventStageOpen = (
  event: Event,
  stage: string,
  params?: EventHasStageParams,
) => {
  const stages = parseJson(event.stages);
  return isEventHasStage(stages?.[stage], params);
};

export const isWebinarEvent = (event: Event) => event.isVirtual === 1;

export const isEventFull = (event: Event) =>
  +event.type === EVENT_TYPES.EVENT_FULL;

export const isFreeEvent = (event: Event) => {
  const { memberPrice, nonMemberPrice, type } = event;

  if (+type === EVENT_TYPES.EVENT_FULL) {
    return false;
  }

  const isEmptyMemberPrice = +memberPrice === 0 || isNaN(+memberPrice);
  const isEmptyNonMemberPrice = +nonMemberPrice === 0 || isNaN(+nonMemberPrice);

  return isEmptyMemberPrice && isEmptyNonMemberPrice;
};

export const isCongress = (event?: Event) => parseBoolean(event?.isCongress);

export const getEventReplaySlotSettings = (event: Event) => {
  const stages = parseJson(event.stages);

  throw new Error('deprecated function: getEventReplaySlotSettings');

  return {
    showSlotReplayInProgram: parseBoolean(stages?.showSlotReplayInProgram),
    showSlotReplayGlobal: parseBoolean(stages?.showSlotReplayGlobal),
  };
};

export const isEventReplayActivated = (event: Event) => {
  const replaySettings = getEventReplaySlotSettings(event);

  throw new Error('deprecated function: isEventReplayActivated');

  return (
    replaySettings.showSlotReplayGlobal ||
    replaySettings.showSlotReplayInProgram
  );
};

export const isSpecialPartner = (partner: Partner) => {
  return [1, 3, '1', '3'].indexOf(partner.type) !== -1;
};

export const sortPartners = (
  eventId: number,
  partners: Partner[],
  isStructural: boolean,
) => {
  if (eventId !== 119) {
    return partners;
  }

  const sortedIds = getPartnersSort(new Date().getDate(), isStructural);

  return sortedIds.reduce((acc: Partner[], partnerId: number) => {
    const partner = partners.find(({ id }) => id == partnerId);
    if (partner) {
      return [...acc, partner];
    }
    return acc;
  }, []);
};

export const getEventNbHours = (event: Event): number => {
  if (isEmpty(event)) {
    return 0;
  }

  return event.accreditationHours ?? 0;
  const startTime = moment(event.startDateTime);
  const endTime = moment(event.endDateTime);

  return endTime.diff(startTime, 'hours');
};

export const getEventNbMinutes = (event: Event): number => {
  if (isEmpty(event)) {
    return 0;
  }
  const startTime = moment(event.startDateTime);
  const endTime = moment(event.endDateTime);
  return endTime.diff(startTime, 'minutes');
};

type EVENT_PAGE =
  | 'RECEPTION'
  | 'LIVE'
  | 'STAGE'
  | 'SESSIONS'
  | 'EXPO'
  | 'DOCS'
  | 'ADVERTISEMENTS'
  | 'LANDING_PAGE'
  | 'SLACK';

export interface EventHasStageParams {
  hadPartnerManager?: boolean;
  isEventAdmin?: boolean;
}

export const isEventHasStage = (
  pageStatus: string | number | boolean,
  params?: EventHasStageParams,
) => {
  if (!pageStatus || isEmpty(pageStatus)) {
    return false;
  }

  switch (pageStatus) {
    case true:
    case 1:
    case '1':
    case 'true':
      return true;
    case false:
    case 0:
    case '0':
    case 'false':
      return false;
    case 'admin':
      return params?.isEventAdmin ?? false;
    case 'partner':
      return (params?.isEventAdmin || params?.hadPartnerManager) ?? false;
  }

  return false;
};

export const getAuthorizedEventPages = (
  event?: Event,
  params?: EventHasStageParams,
  isPartnerCommunity?: boolean,
) => {
  const withPages = {
    withLandingPage: false,
    withReception: true,
    withLive: false,
    withStage: false,
    withTV: false,
    withSessions: false,
    withSpeakers: true,
    withExpo: false,
    withDocs: false,
    withAdvertisements: false,
    withSlack: false,
    withEvaluation: false,
  };

  if (!event) {
    return withPages;
  }

  switch (event.type) {
    case EVENT_TYPES.EVENT_QUICK:
      return {
        withLandingPage: false,
        withReception: true,
        withLive: false,
        withStage: false,
        withTV: false,
        withSessions: +event.slotsCount > 1,
        withSpeakers: true,
        withExpo: isPartnerCommunity,
        withDocs: false,
        withAdvertisements: false,
        withSlack: false,
        withEvaluation: false,
      };
    case EVENT_TYPES.EVENT_FULL: {
      const stages = parseJson(event.stages);

      return {
        withLandingPage: isEventHasStage(stages?.proShowHome, params),
        withReception: true,
        withLive: isEventHasStage(stages?.proShowLive, params),
        withStage: isEventHasStage(stages?.proShowScene, params),
        withTV: isEventHasStage(stages?.proShowTV, params),
        withSessions: isEventHasStage(stages?.proShowSessions, params),
        withSpeakers: isEventHasStage(stages?.showOrateurs, params),
        withExpo: isEventHasStage(stages?.proShowExpo, params),
        withDocs: isEventHasStage(stages?.proShowDocs, params),
        withAdvertisements: isEventHasStage(stages?.proShowAnnonces, params),
        withSlack: isEventHasStage(stages?.proShowSlack, params),
        withEvaluation: isEventHasStage(stages?.showEvaluation, params),
      };
    }
  }

  return withPages;
};

export type AuthorizedEventPages = ReturnType<typeof getAuthorizedEventPages>;

export const isEventLive = (event: Event) =>
  moment(belgiumCurrentdate).isBetween(
    moment(event.startDateTime),
    moment(event.endDateTime),
  );

export const isEventPast = (event: Event) =>
  moment(belgiumCurrentdate).isAfter(moment(event.endDateTime));

export const isEventUpcoming = (event: Event) =>
  moment(belgiumCurrentdate).isBefore(moment(event.startDateTime));

export const isEventRegistrationOpen = (event: Event) =>
  +event.status === EVENT_STATUS.ACTIVE; /*&& !isEventPast(event)*/

export const isEventReplayable = (event: Event) => +event.isReplayable === 1;

export const isEventDatePast = (endDateTime: string) =>
  moment(belgiumCurrentdate).isAfter(moment(endDateTime));

export const isEventDateUpcoming = (startDateTime: string) =>
  moment(belgiumCurrentdate).isBefore(moment(startDateTime));

export const isEventDateLive = (startDateTime: string, endDateTime: string) =>
  moment(belgiumCurrentdate).isBetween(
    moment(startDateTime),
    moment(endDateTime),
  );

export const getRegisterButtonTitle = (
  event: Event,
  coupon?: Coupon | null,
) => {
  if (isFreeEvent(event)) {
    return 'register';
  }

  if (isFreeCoupon(event, coupon)) {
    return 'Register for free';
  }

  if (isEventFull(event)) {
    return 'Register now';
  }

  return 'Buy training';
};

export const getEventRegisterLink = (
  event: Event,
  forceHasCycle?: boolean,
  multiDateIndex?: number,
) => {
  if (!event) {
    return '';
  }
  let link = '';

  if ((event.eventCycles?.length ?? 0) > 0 || forceHasCycle) {
    link = `${URLS.planSelector}?eventId=${event.id}${
      multiDateIndex ? `&dateIndex=${multiDateIndex}` : ''
    }`;
  } else {
    const receptionPage = bindWebinarLink(
      URLS.webinar.reception.home,
      String(event.id ?? 0),
      `tool=${REGISTRATION_TAB}`,
    );

    const registrationPage = bindWebinarLink(
      URLS.webinar.reception.registration.root,
      String(event.id ?? 0),
      `tool=${CHAT_TAB}`,
    );

    link = isFreeEvent(event) ? receptionPage : registrationPage;
  }

  return link;
};

export const isFreeCoupon = (event: Event, coupon?: Coupon | null) => {
  if (coupon) {
    const reduction = coupon.reductions?.find(
      (item) => item.type === COUPON_REDUCTION_TYPE.EVENT,
    );

    if (
      reduction &&
      +event.memberPrice === +reduction.reductionMembre &&
      +event.nonMemberPrice === +reduction.reductionNonMembre
    ) {
      return true;
    }
  }

  return false;
};

export const speakersEventToString = (event: Event, limit: number = 3) => {
  if (!event) {
    return '';
  }

  const speakersData = event['speakers-abstract'];
  if (!speakersData) {
    return null;
  }

  const speakersLength = speakersData.count;

  if (speakersData.count > limit) {
    return `${speakersData.count} ${t('Speakers').toLowerCase()}.`;
  }

  return (
    speakersData.speakers?.reduce(
      (acc, { firstName, lastName }, index) =>
        `${acc} ${firstName} ${lastName}${
          index === speakersLength - 1 ? '' : ','
        }`.trim(),
      '',
    ) ?? ''
  );
};

export const speakersToString = (
  resource: Event | Cycle,
  limit: number = 3,
) => {
  if (!resource || !resource['speakers-abstract']) {
    return '';
  }

  const { count: speakersCount, speakers: speakersData } = resource[
    'speakers-abstract'
  ];

  if (speakersCount > limit) {
    return `${speakersCount} ${t('Speakers').toLowerCase()}`;
  }

  return (
    [...speakersData].reduce(
      (acc, { firstName, lastName }, index) =>
        `${acc} ${firstName} ${lastName}${
          index === speakersCount - 1 ? '' : ','
        }`.trim(),
      '',
    ) ?? ''
  );
};

export const getCalendarEventData = (
  event: Event,
  language: Language,
): CalendarEventType => {
  return {
    id: event.id,
    title: getByLanguage(event, 'name', language),
    description: getByLanguage(event, 'description', language),
    startTime: event.startDateTime,
    endTime: event.endDateTime,
    location: getByLanguage(event, 'place', language),
  };
};

export const isReplayExpiredForUser = (guest: any) => {
  const { dateEndOfReplay } = guest;
  return !dateEndOfReplay || moment().isAfter(moment(dateEndOfReplay));
};

export const isEndOfReplayExpired = (matchedEndOfReplay: string | null) => {
  return moment().isAfter(moment(matchedEndOfReplay));
};

export const formatAccessibleDays = (accessibleDays: string) => {
  const days = accessibleDays.split(',');
  let isContinued = true;

  if (days.length <= 1) {
    return accessibleDays.trim();
  }

  for (let i = 0; i < days.length - 1; i++) {
    const dayA = moment(days[i], 'DD/MM/YYYY');
    const dayB = moment(days[i + 1], 'DD/MM/YYYY');

    if (!dayB.isSame(dayA.add(1, 'days'))) {
      isContinued = false;
      break;
    }
  }

  const allDays = [...days];
  if (!isContinued) {
    const lastDay = allDays.pop();
    // lastDay = lastDay?.substring(0, lastDay.lastIndexOf('/'));

    return `${allDays
      .map((day) => day?.substring(0, day.lastIndexOf('/')))
      .join(', ')} ${t('and')} ${lastDay}`;
  }

  const firstDay = moment(allDays.shift(), 'DD/MM/YYYY');
  const lastDay = moment(allDays.pop(), 'DD/MM/YYYY');

  const isSameMonth = firstDay.isSame(lastDay, 'month');
  const isSameYear = firstDay.isSame(lastDay, 'years');

  if (!isSameYear) {
    return `${t('from_date')} ${firstDay.format('DD/MM/YY')} ${t(
      'to_date',
    )} ${lastDay.format('DD/MM/YY')}`;
  }

  if (!isSameMonth) {
    return `${t('from_date')} ${firstDay.format('DD/MM')} ${t(
      'to_date',
    )} ${lastDay.format('DD/MM/YY')}`;
  }

  return `${t('from_date')} ${moment(firstDay, 'DD/MM/YYYY').format('DD')} ${t(
    'to_date',
  )} ${moment(lastDay, 'DD/MM/YYYY').format('DD/MM/YY')}`;
};

export const getEventPlanPrice = (
  plan: EventPlan,
  coupons: Coupon[],
  guest?: Guest,
) => {
  const reduction = getCouponReduction(
    coupons,
    COUPON_REDUCTION_TYPE.PLAN,
    +plan.id,
  );

  return getGuestPrice(plan, guest, reduction);
};

export const getEventOptionPrice = (
  option: EventOption,
  coupons: Coupon[],
  guest?: Guest,
) => {
  const reduction = getCouponReduction(
    coupons,
    COUPON_REDUCTION_TYPE.OPTION,
    +option.id,
  );

  return getGuestPrice(option, guest, reduction);
};

export const isEventIncludedInPremium = (
  premiumCycle?: Cycle,
  eventId?: number,
  communityId?: number,
) => {
  return (
    premiumCycle?.eventCycles?.some((pc) => pc.event == eventId) &&
    (communityId == FFF_ID || !communityId)
  );
};

export const formatDateEndOfReplay = (
  date?: string | null,
  entityEndDate?: string | null,
) => {
  if (date) {
    const dateObj = new Date(date.replace(/-/g, '/'));
    return new Intl.DateTimeFormat('fr-FR').format(dateObj);
  }

  const currentDate = new Date();

  if (entityEndDate) {
    const parsedEntityEndDate = new Date(entityEndDate.replace(/-/g, '/'));

    if (parsedEntityEndDate > currentDate) {
      const replayEndDate = new Date(
        parsedEntityEndDate.setMonth(
          parsedEntityEndDate.getMonth() + REPLAY_UPTIME,
        ),
      );
      return new Intl.DateTimeFormat('fr-FR').format(replayEndDate);
    }
  }

  const replayEndDate = new Date(
    currentDate.setMonth(currentDate.getMonth() + REPLAY_UPTIME),
  );
  return new Intl.DateTimeFormat('fr-FR').format(replayEndDate);
};

export const calculateTimeDifference = (
  startDateTime?: string,
  endDateTime?: string,
) => {
  if (!startDateTime) {
    return { hours: null, minutes: null, seconds: null };
  }

  const belgiumCurrentdate = new Date().toLocaleString('EN', {
    timeZone: 'Europe/Brussels',
  });
  const dateDifference = endDateTime
    ? +new Date(startDateTime) - +new Date(endDateTime)
    : +new Date(startDateTime) - +new Date(belgiumCurrentdate);
  const difference = Math.abs(dateDifference);
  return {
    hours: Math.floor(difference / (1000 * 60 * 60)),
    minutes: Math.floor((difference / 1000 / 60) % 60),
    seconds: Math.floor((difference / 1000) % 60),
  };
};

export const getEventDateWithClosestDateTime = (event?: Event) => {
  if (!event) {
    return null;
  }

  const now = new Date().getTime();
  const dates = event?.eventDates?.map((evt) =>
    new Date(evt.startDateTime).getTime(),
  );
  dates?.push(new Date(event.startDateTime).getTime());

  const closest = dates?.reduce((a, b) => {
    const diffA = Math.abs(now - a);
    const diffB = Math.abs(now - b);
    return diffA < diffB ? a : b;
  });

  const formatedClosest = closest
    ? moment(closest).format('YYYY-MM-DD HH:mm:ss')
    : null;

  const eventDate =
    closest && event.startDateTime !== formatedClosest
      ? event?.eventDates?.find((evt) => evt.startDateTime === formatedClosest)
      : null;

  return eventDate;
};

export const liveEventFirst = (e: EventCycle[]) => {
  const sortedEvents: Event[] = [];
  const liveEvents: Event[] = [];

  const events: Event[] = e?.map(
    ({ eventsAbstract }) => eventsAbstract as Event,
  );
  const eventsList = sortEventByStartDateTime(events);

  eventsList.forEach((e) => {
    if (!isEventPast(e)) liveEvents.push(e);
    else sortedEvents.push(e);
  });

  return liveEvents.concat(sortedEvents);
};

export const isAllPackActive = (packs: any[]) => {
  return Object.values(packs).every((value) => value?.status != 1);
};

export const checkIfPackIdExist = (matchedPackIds: number[], packs: any) => {
  let exist = false;

  matchedPackIds.map((id) => {
    if (id in packs) {
      exist = true;
      return;
    }
  });
  return exist;
};

export const isUserHasReplayOption = (
  day?: string,
  options?: EventOption[],
) => {
  const replay = options?.some((op) => op.id === OPTION_REPLAY_ALL_ID);

  if (replay) {
    return true;
  }

  if (day?.includes('30')) {
    return options?.some((op) => op.id === OPTION_REPLAY_DAY1_ID);
  }

  if (day?.includes('01')) {
    return options?.some((op) => op.id === OPTION_REPLAY_DAY2_ID);
  }

  return false;
};

const getPackGroupDescription = (packs: EventPack[], language: Language) => {
  for (const p of packs) {
    const description: string = getByLanguage(p, 'description', language);
    if (!isEmpty(description)) {
      return description;
    }
  }
};

export const getEventActivePacks = (packs: EventPack[]) => {
  const dataArray = Object.values(packs);

  const filteredPacks = dataArray.filter((pack) => pack.status !== '1');

  const updatedPacks: any = {};
  filteredPacks.forEach((item) => {
    updatedPacks[item.id] = item;
  });

  return updatedPacks;
};

export const groupPacks = (packs: EventPack[], language: Language) => {
  const packsByGroup = groupBy(packs, 'packGroup');
  const packGroups = [];

  for (const groupeKey in packsByGroup) {
    const packs = packsByGroup[groupeKey];
    const description = getPackGroupDescription(packs, language);
    packGroups.push({
      label: t(`${groupeKey.toLocaleLowerCase()}_pack`),
      description,
      packs,
    });
  }

  return packGroups;
};

export const getSpeakerPageUrl = (speaker: Speaker, language: Language) =>
  `${TTP_BLOG_URL}/${language}${speaker.blogUrlPath}?without_backnav=1`;

export const getEventIcsUrl = (
  eventId: number,
  userId: number,
  language: Language,
) => {
  const encodedUserId = btoa(String(userId)).replace(/=/g, '');

  return `${TTP_API_URL}/event/ics-event/${language}/${eventId}/${encodedUserId}`;
};

export const getNearestEvent = (events: Event[]): Event => {
  const currentDate = new Date();

  const futureEvents = events.filter((event) => {
    const eventDate = new Date(event.startDateTime);
    return eventDate > currentDate;
  });

  if (futureEvents.length === 0) {
    return events[0];
  }

  futureEvents.sort((a, b) => {
    const dateA = new Date(a.startDateTime).getTime();
    const dateB = new Date(b.startDateTime).getTime();
    return dateA - dateB;
  });

  return futureEvents[0];
};

export const sortEventByStartDateTime = (
  events: Event[],
  sortOrder: 'ASC' | 'DESC' = 'ASC',
): Event[] => {
  const eventsList: Event[] = [];

  events.map((event) => {
    eventsList.push(event);

    if (event.eventDates?.length) {
      event.eventDates.map((evt) => {
        const updatedEvent = {
          ...event,
          startDateTime: evt.startDateTime,
          endDateTime: evt.endDateTime,
          multiDateIndex: evt.id,
        };
        eventsList.push(updatedEvent);
      });
    }
  });

  const sortByStartDateTime = (eventA: Event, eventB: Event) => {
    const dateTimeA = new Date(eventA.startDateTime).getTime();
    const dateTimeB = new Date(eventB.startDateTime).getTime();

    if (sortOrder === 'ASC') {
      if (dateTimeA < dateTimeB) {
        return -1;
      }
      if (dateTimeA > dateTimeB) {
        return 1;
      }
    } else if (sortOrder === 'DESC') {
      if (dateTimeA > dateTimeB) {
        return -1;
      }
      if (dateTimeA < dateTimeB) {
        return 1;
      }
    }
    return 0;
  };

  const sortedEvents = eventsList.slice().sort(sortByStartDateTime);

  return sortedEvents;
};

export const adaptEventDates = (
  event: Event,
  selectedDateId: number,
): Event => {
  const selectedDate = event.eventDates?.find(
    (evt) => evt.id == selectedDateId,
  );

  if (!isEmpty(selectedDate)) {
    const startDateTime = selectedDate?.startDateTime ?? '';
    const endDateTime = selectedDate?.endDateTime ?? '';

    return { ...event, startDateTime, endDateTime };
  }

  return event;
};

export const prepareEventsDates = (
  events: Event[],
  sortOrder: 'ASC' | 'DESC' = 'ASC',
  period: 'all' | 'future' | 'past' = 'all',
) => {
  const sortedEvents = sortEventByStartDateTime(events, sortOrder);

  const filteredEvents: Event[] = [];
  sortedEvents.forEach((event) => {
    const isPast = isEventPast(event);
    const isFuture = isEventUpcoming(event) || isEventLive(event);

    if (
      (isPast && period == 'past') ||
      (isFuture && period == 'future') ||
      period == 'all'
    ) {
      filteredEvents.push(event);
    }
  });

  return filteredEvents;
};

export const isQuestionValid = (question?: string) => {
  const questionObj: Question | undefined = parseJson(question);

  return (
    questionObj?.questionText?.length &&
    ['input', 'select', 'multiSelect'].includes(questionObj?.type) &&
    (questionObj?.options?.length ||
      !['select', 'multiSelect'].includes(questionObj?.type)) &&
    (questionObj?.defaultOptions?.every((option) =>
      questionObj?.options.includes(option),
    ) ||
      !questionObj?.defaultOptions?.length)
  );
};

export const prepareEventList = (
  events: Event[],
  period: 'all' | 'future' | 'past' = 'all',
) => {
  const sort = period === 'future' ? 'ASC' : 'DESC';
  const sortedEvents = sortEventByStartDateTime(events, sort);

  const filteredEvents: Event[] = [];
  sortedEvents.forEach((event) => {
    const isPast = isEventPast(event);
    const isFuture = isEventUpcoming(event) || isEventLive(event);

    if (
      (isPast && period === 'past') ||
      (isFuture && period === 'future') ||
      period === 'all'
    ) {
      filteredEvents.push(event);
    }
  });

  return filteredEvents;
};
