import PageContainer from 'components/Layout/PageContainer';
import React, { useEffect } from 'react';
import s from './PremiumHero.module.scss';
import Markdown from 'markdown-to-jsx';
import t from 'i18n';
import cn from 'classnames';
import PriceLabel from 'ui/PriceLabel';
import ActionButton from 'HomeSlides/Common/ActionButton/ActionButton';
import FeaturesList from 'Watch/WatchTools/FeaturesList/FeaturesList';
import _ from 'i18n';
import { ReactComponent as EducationIcon } from 'assets/icons/cil_education.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/video.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { ReactComponent as UsersIcon } from 'assets/icons/users.svg';
import { ReactComponent as AwardIcon } from 'assets/icons/award.svg';
import { ReactComponent as CancelIcon } from 'assets/icons/cancel.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down-circle.svg';
import { FetchParams, ResourceState } from 'store/Resource/types';
import { Cycle, CYCLE_STATUS } from 'store/Cycle/types';
import { Filter, SingleValueFilter } from 'services/Filter';
import {
  formatDateFromTo,
  getCyclePrice,
  getPremiumCyclePrice,
  TYPE_PREMIUM,
} from 'utils';
import TTPLoader from 'Common/TTPLoader';
import {
  OUR_OFFERS_OFFFCOURSE_URL,
  PREMIUM_DEFAULT_PRICES,
  PREMIUM_OFFER_OFFFCOURSE_URL,
  OFFFCOURSE_URL,
} from 'config';
import { Language } from 'store/types';
import PremiumPlans from './PremiumPlans/PremiumPlans';
import useResponsive from 'hooks/useResponsive';

interface Props {
  premiumOffersResource: ResourceState<Cycle>;
  fetchPremiumOffers: (params?: FetchParams) => void;
  language: Language;
}

const PremiumHero = ({
  premiumOffersResource: {
    fetching: isPremiumFetching,
    fetched: isPremiumFetched,
    items: premiumOffers,
  },
  fetchPremiumOffers,
  language,
}: Props) => {
  const { isMobile } = useResponsive();
  const premiumOffer = isPremiumFetched && premiumOffers[0];
  const endDate = formatDateFromTo(
    '',
    premiumOffers[0]?.endDateTime,
    language,
    'L',
  );
  const premiumOfferYear =
    premiumOffer && premiumOffer?.startDateTime.substr(0, 4);
  const { nonMemberPrice: cyclePrice } = premiumOffer
    ? getCyclePrice(premiumOffer)
    : {
        nonMemberPrice: {
          originalPrice: PREMIUM_DEFAULT_PRICES.ORIGINAL_PRICE,
          price: PREMIUM_DEFAULT_PRICES.PRICE,
        },
      };

  const { nonMemberPrice } = premiumOffer
    ? getPremiumCyclePrice(premiumOffer)
    : {
        nonMemberPrice: {
          yearlyPrice: PREMIUM_DEFAULT_PRICES.PRICE,
          monthlyPrice: PREMIUM_DEFAULT_PRICES.MONTHLY_PRICE,
        },
      };
  const premiumPrice = nonMemberPrice;

  useEffect(() => {
    const filters: Filter[] = [
      new SingleValueFilter('type', 'eq', TYPE_PREMIUM),
      new SingleValueFilter('status', 'neq', CYCLE_STATUS.INACTIVE),
    ];
    fetchPremiumOffers({
      sort: [{ property: 'startDateTime', dir: 'DESC' }],
      filters,
    });
  }, []);

  if (isPremiumFetching) {
    return (
      <PageContainer className="p-t-0">
        <div className={s.ttp_loader}>
          <TTPLoader />
        </div>
      </PageContainer>
    );
  }

  return (
    <main>
      <PageContainer asFullPage className={cn(s.banner)}>
        <div className={s.titles}>
          <div className={s.container}>
            <h1>{t('Premium subscription')}</h1>
            <h2>{t('Premium description')}</h2>
            <h6>{t('Premium benefit')}</h6>
            <PremiumPlans premiumPrice={premiumPrice} />
            <div className={s.action}>
              <ActionButton
                isExternal
                theme="redOrange"
                name={t('subscribe_via_offfcourse')}
                linkStyle={{
                  height: '43px',
                }}
                classname={!isMobile ? s.actionButton : s.actionButtonMobile}
                link={
                  premiumOffer
                    ? `${OFFFCOURSE_URL}/${language}/cycle/${premiumOffer.id}/reception`
                    : OFFFCOURSE_URL + '/cycle/56/reception'
                }
                isPathName={true}
                target="_blank"
                rel="noopener noreferrer"
              />
              <span className={s.premiumAllCourses}>
                {t('premiumAllCourses')}
              </span>
            </div>
          </div>
        </div>
        <div className={s.svg}>
          <FeaturesList
            items={[
              {
                label: _('all_offfcourse_trainings'),
                icon: () => (
                  <EducationIcon width="28" height="28" fill="#FB1E5B" />
                ),
              },
              {
                label: _('access_live_and_replay'),
                icon: () => (
                  <VideoIcon
                    width="28"
                    height="28"
                    fill="#FB1E5B"
                    stroke="#FB1E5B"
                  />
                ),
              },
              {
                label: _('share_your_account'),
                icon: () => <UsersIcon width="28" height="28" fill="#FB1E5B" />,
              },
              {
                label: _('certification_included'),
                icon: () => <AwardIcon width="28" height="28" fill="#FB1E5B" />,
              },
              {
                label: _('cancel_anytime'),
                icon: () => (
                  <CancelIcon width="28" height="28" fill="#FB1E5B" />
                ),
              },
            ]}
          />
        </div>
      </PageContainer>
    </main>
  );
};

export default PremiumHero;
